import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import '../../Project/Utility/common.css';

import Img1 from '../Landing/LandingImages/Carousel/Paint1.jpg'
import Img2 from '../Landing/LandingImages/Carousel/Paint2.jpg'
import Img3 from '../Landing/LandingImages/Carousel/Paint3.jpg'
import Img4 from '../Landing/LandingImages/Carousel/Paint4.jpg'
import Img5 from '../Landing/LandingImages/Carousel/Paint5.jpg'
import Img6 from '../Landing/LandingImages/Carousel/Paint6.jpg'
import Img7 from '../Landing/LandingImages/Carousel/Paint7.jpg'
import Img8 from '../Landing/LandingImages/Carousel/Paint8.jpg'



const LandingCaro = () => {
  return (
    <div className="container Caro bg-dark">
      <Carousel autoPlay infiniteLoop width="100%" dynamicHeight="0px">
        <div>
          <img src={Img1} alt={Img1} />
          {/* <p className='legend'>Bashir</p> */}
        </div>
        <div>
          <img src={Img2} alt={Img2} />
        </div>
        <div>
          <img src={Img3} alt={Img3} />
        </div>
        <div>
          <img src={Img4} alt={Img4} />
        </div>
        <div>
          <img src={Img5} alt={Img5} />
        </div>
        <div>
          <img src={Img6} alt={Img6} />
        </div>
        <div>
          <img src={Img7} alt={Img7} />
        </div>
        <div>
          <img src={Img8} alt={Img8} />
        </div>
      </Carousel>
    </div>

  )
}

export default LandingCaro