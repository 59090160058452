import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import "aos/dist/aos.css";
import Footer from '../Footer/Footer';

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

const AboutUS = () => {
  const navigate = useNavigate()

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    })
  })


  return (
    <main>
      <div className=''>

        {/*  <div className='d-flex justify-content-end' style={{ background: "darkgreen" }}>
          <CancelPresentationIcon className='cancelButton' onClick={() => { navigate('/') }} />
  </div>*/}

        <div className='d-flex flex-column justify-content-between p-4 text-center'>
          <div>
            <h1 style={{ color: "blue", textShadow: "2px 2px 10px gray" }}><strong><u>ABOUT US</u></strong></h1>
          </div>
          <div className='m-2'>
            <p style={{ fontWeight: "bold", fontFamily: "serif", textShadow: "10px 10px 10px gray" }}>We are dealing in all kind of best quality Paints, Varnishes as well as all kind of Paint related Raw Materials, wooden polishes and accessories/(arms)...</p>
          </div>
          <div className='m-2'>
            <p style={{ fontWeight: "bold", fontFamily: "serif", textShadow: "10px 10px 10px gray" }}>We are the recognized dealer of <strong>Brighto Paints (Pvt.) Ltd.</strong>, as well as, other reputable Paint and Material Brands in the market.</p>
          </div>
          <div className='m-2'>
            <p style={{ fontWeight: "bold", fontFamily: "serif", textShadow: "10px 10px 10px gray" }}>Our aim is to offer our esteemed customer the best quality along with the best facilities.</p>
          </div>
        </div>
      </div>

      <div className='d-flex flex-column col-lg-12 bg-success' style={{ border: "2px ridge" }}>
        <h3 className='col-12 text-white'><u>Find Us here:</u></h3>
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1702.9694223473361!2d74.27879071805378!3d31.388249746332278!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190090825f07a7%3A0xd362df5c2f098cfe!2sSOFIA%20CENTER!5e0!3m2!1sen!2s!4v1704785511699!5m2!1sen!2s" width="100%" height="450" style={{ border: "0;" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> **/}

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13623.831587127686!2d74.27855907544635!3d31.387724436498193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391901ea30ed528b%3A0xdc4a48da3f4c8883!2sBashir%20Paint%20House!5e0!3m2!1sen!2s!4v1705304790037!5m2!1sen!2s" width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>

      <Footer />

    </main>
  )
}

export default AboutUS