import React, { useEffect } from 'react';
import LandingCaro from './LandingCaro';
import AOS from 'aos';
import "aos/dist/aos.css";
import Footer from '../Footer/Footer';

import ImageS1 from '../Landing/LandingImages/Carousel/Side1.jpg'
import ImageS2 from '../Landing/LandingImages/Carousel/Side2.jpg'
import ImageS3 from '../Landing/LandingImages/Carousel/Side3.jpg'
import ImageS4 from '../Landing/LandingImages/Carousel/Side4.jpg'

const Landing = () => {

  useEffect(() => {
    AOS.init();
  })

  return (
    <div style={{ height: "70vh" }}>
      <div className='row m-0 p-0'>

        <div className='d-flex flex-column justify-content-around m-0 p-0 col-lg-3 col-md-12 col-sm-12 col-12 order-2 order-lg-0 order-md-2 order-sm-2 Caro'
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img src={ImageS1} alt="S1" />
          <img src={ImageS3} alt="S3" />
        </div>

        <div className='container col-lg-5 col-md-12 col-sm-12 order-1'>
          <LandingCaro />
        </div>

        <div className='d-flex flex-column justify-content-around m-0 p-0 col-lg-3 col-md-12 col-sm-12 order-lg-2 col-12 order-3 order-md-3 order-sm-3 Caro'
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <img src={ImageS2} alt="S2" />
          <img src={ImageS4} alt="S4" />
        </div>

      </div>

      <Footer />

    </div>

  )
}

export default Landing