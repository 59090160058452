import React, { useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AOS from 'aos';
import "aos/dist/aos.css";


const Footer = () => {

  useEffect(() => {
    AOS.init();
  })

  return (
    <div className='row m-0 p-0'
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="500"
    >

      <div className='footer_BG mt-2 p-2  justify-content-between'>

        <div className='d-flex flex-column col-lg-8 col-md-12 col-sm-12 col-12'>
          <h3 style={{ textShadow: "10px 10px 10px gray" }} className='col-12 text-white'><u>Contact Us:</u></h3>
          <div className='text-white'><PhoneAndroidIcon /> Fahad Mansoor +92 302 1455399</div>
          <div className='text-white'><PhoneAndroidIcon /> Talha Mansoor +92 322 4394847</div>
          <div className='text-white'><EmailIcon /> fahad.mansoor49@gmail.com</div>
          <div className='text-white'><LocationOnIcon /> Sofia center, Main defense Road, opp. KEMC, Lahore-Pakistan.</div>
          <br />
          <div className='text-white'><AccessAlarmIcon /> MON. To SAT. 8:30 AM TO 7:00 PM - ( SUN. 8:30 AM TO 2:00 PM )</div>
        </div>
        <div className='d-flex flex-column justify-content-end pt-5 col-lg-3 col-md-12 col-sm-12 col-12'>
          <div className='col-12 text-white small'><u>Powered by:</u></div>
          <div className='text-white small'>SwiftErp Solutions.</div>
        </div>

      </div>

      <div className='d-flex uArrow justify-content-center' onClick={() => { window.scrollTo(0, 0) }}>
        <HomeIcon />
      </div>
    </div>
  )
}

export default Footer