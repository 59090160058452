import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'
// import AOS from 'aos';
// import "aos/dist/aos.css";

import Logo from '../Landing/LandingImages/BPH_LOGO.jpeg'
import '../Utility/common.css';

/* Material UI Icons */
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

// import { colors } from '@mui/material';

// import Home from '../../Project/LandingImages/Icons/Home48P.png'

const LandingHead = (props) => {

  const navigate = useNavigate()

  const containerRef = useRef(null);
  const [deviceHeight, setDiveHeight] = useState(0);
  const [vpw, setVpw] = useState(window.innerWidth);
  const [menuOption, setMenuOption] = useState(false);

  const [showAbout, setShowAbout] = useState(false);
  // const [showContact, setShowContact] = useState(false);
  // const [showCustomer, setShowCustomer] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(true);

  // const [menuDisplay, setMenuDisplay] = useState('none');

  useEffect(() => {
    // AOS.init();
    window.addEventListener('resize', windowResizeEvent)
    setDiveHeight(containerRef.current.clientHeight);
    props.getPaddingTop((deviceHeight + 20).toString() + "px");
  })

  const windowResizeEvent = () => {
    setVpw(window.innerWidth)
  }

  return (
    <div className='row m-0 p-0 headFix' ref={containerRef}
    // data-aos="fade-down"
    // data-aos-easing="linear"
    // data-aos-duration="500"
    >

      <div className='row m-0 p-0 headBG headShadow justify-content-lg-between justify-content-md-center justify-content-sm-center align-items-center'>

        <div className='d-flex col-lg-1 col-md-12 col-sm-12 col-12 justify-content-lg-start justify-content-center align-items-center pt-1 pb-1'>
          <img className='radius headLogo menuOnHover' src={Logo} alt='Logo' onClick={() => { navigate('/') }} />
        </div>

        <div className='d-flex col-lg-10 col-md-12 col-sm-12 col-12 justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center align-items-center' >
          <div className='headTitle fontSizeOnSmall'>Bashir Paint House.</div>
        </div>

        <div className='menuOnHover d-flex col-lg-1 col-md-12 col-sm-12 col-12 justify-content-lg-end justify-content-center align-items-center pt-1 pb-1'>
          {
            menuOption === false ?
              <MenuIcon fontSize='large' onClick={() => { setMenuOption(true) }} />
              :
              <MenuOpenIcon fontSize='large' onClick={() => { setMenuOption(false) }} />
          }
        </div>
      </div>
      {
        menuOption ?
          <ul className='d-flex flex-column menuOnHover m-0 p-2 col-lg-3 col-12' style={{ top: deviceHeight, position: "absolute", zIndex: "5", right: "0", border: "1px solid black" }}>
            <li>
              <Link className='link' exact to='/' onClick={() => { setMenuOption(false) }}>HOME</Link>
            </li>
            <li>
              <Link className='link' exact to='/about' onClick={() => { setMenuOption(false) }}>ABOUT</Link>
            </li>
            <li>
              <Link className='link' exact to='/product' onClick={() => { setMenuOption(false) }}>GALLERY</Link>
            </li>
            <li>
              <Link className='link' exact to='/contact' onClick={() => { setMenuOption(false) }}>CONTACT</Link>
            </li>
            {/**           
            <li>
              <Link className='link' exact to='/customer' onClick={() => { setMenuOption(false) }}>ORDER & INQUIRIES</Link>
            </li>
            */}

            {/* {
              isAdmin ?
                <li>
                  <Link className='link' exact to='/adminConsole' onClick={() => { setMenuOption(false) }}>ADMIN CONSOLE</Link>
                </li>
                :
                <li>
                  <Link style={{ pointerEvents: "none", opacity: "0.5" }} className='link' exact to='/adminConsole' onClick={() => { setMenuOption(false) }}>ADMIN CONSOLE</Link>
                </li>
            } */}

          </ul>
          :
          null
      }
    </div>
  )
}

export default LandingHead