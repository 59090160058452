import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import './Project/Utility/common.css'

import LandingHead from './Project/Landing/LandingHead';
import Landing from './Project/Landing/Landing';
import Products from './Project/Products/Products';
// import Footer from './Project/Footer/Footer';

import AboutUS from './Project/Home/AboutUS';
import ContactUS from './Project/Home/ContactUS';
import Customers from './Project/Home/Customers';
// import Login from './Project/Home/Login';

const App = () => {

  const [paddingTopGlobal, setPaddingTopGlobal] = useState("0px");

  const getPaddingTop = (paddingTop) => {
    // console.log('Padding Top: '+paddingTop);
    setPaddingTopGlobal(paddingTop);
  }

  return (
    <div className='d-flex row m-0 p-0'>

      <LandingHead getPaddingTop={getPaddingTop} />

      <div style={{ paddingTop: paddingTopGlobal }}>
        <Routes basename='/'>
          <Route path="/" element={<Landing />} />
          <Route path="/about" element={<AboutUS />} />
          <Route path="/product" element={<Products />} />
          <Route path="/contact" element={<ContactUS />} />
          <Route path="/customer" element={<Customers />} />
          {/* <Route path="/adminConsole" element={<Login />} /> */}
          <Route path="*" element={<Landing />} />

        </Routes>
      </div>

    </div>
  );
}

export default App;
