import React, { useState, useEffect } from 'react';
import ProductData from './ProductData';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import AOS from 'aos';
import "aos/dist/aos.css";


const Products = () => {

  useEffect(() => {
    AOS.init();
  })


  const [productList, setProductList] = useState(ProductData);

  const filterProduct = (userCategory) => {
    // console.log(userCategory)
    const updatedCategory = ProductData.filter((currCategory) => {
      return currCategory.Category === userCategory;
    });
    setProductList(updatedCategory);
  }

  return (
    <div>
      <div className='row m-0 p-1 mt-5 text-white text-center bg-warning smallShadow'>
        <div className='d-flex justify-content-between'>
          <Button variant="contained" color="success" size='small' onClick={(e) => { console.log(e); filterProduct("Interior") }}>
            Interior Coatings
          </Button>
          <Button variant="contained" color="success" size='small' onClick={() => { filterProduct("Exterior") }}>
            Exterior Coatings
          </Button>
          <Button variant="contained" color="success" size='small' onClick={() => { filterProduct("Luxury") }}>
            Luxury Coatings
          </Button>
          <Button variant="contained" color="success" size='small' onClick={() => { setProductList(ProductData) }}>
            All
          </Button>

        </div>
      </div>

      <div>
        <div className='row m-0 p-0 justify-content-between mt-5 mb-5 gap-4' style={{ width: "100%" }}>
          {
            productList.map((proData, idx) => {
              const { image, Category } = proData;
              return (
                // <div>
                <div key={idx} className='d-flex flex-column align-items-center text-center bg-light radius smallShadow p-2' style={{ width: "140px" }}
                  data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000"
                >
                  <img src={image} alt={image} width={130} />
                  <div style={{ fontSize: "12px" }}>{Category}</div>
                </div>
                // </div>
              )
            })
          }
        </div>
      </div>

      <Footer />

    </div>
  )
}

export default Products