import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import "aos/dist/aos.css";
import Footer from '../Footer/Footer';

// import TuxMail from './TuxMail';
import Button from '@mui/material/Button';
// import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
// import EmailIcon from '@mui/icons-material/Email';

const ContactUS = () => {
  const navigate = useNavigate();
  // const [emailBox, setEmailBox] = useState(false);

  const [loginName, setLoginName] = useState();
  const [fullName, setFullName] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [password, setPassword] = useState();
  const [confPassword, setConfpassword] = useState();
  const [geoLocation, setGeoLocation] = useState();

  useEffect(() => {
    AOS.init();
    if ("geolocation" in navigator) {
      console.log("Available");
    } else {
      console.log("Not Available");
    }
    if (navigator.geolocation) {

      navigator.geolocation.watchPosition(function (position) {
        console.log(position)
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
      });
    }
  })

  return (
    <div className='row m-0 p-0'>
      <div className='d-flex flex-column align-items-center p-xl-3 p-lg-3 p-md-2 p-sm-1 bg-light' style={{ border: "1px solid black" }}>
        <div className='headBG text-white col-12 text-center' style={{ borderRadius: "5px", boxShadow: "4px 4px 15px grey" }}
          data-aos="zoom-in"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <h1>REGISTRATION FORM</h1>
        </div>

        {/** FORM */}
        <div className='d-flex mt-3 flex-column col-lg-4 col-md-12 col-sm-12 col-12 p-xl-4 p-lg-4 p-md-2 p-sm-1' style={{ background: "silver", borderRadius: "5px", boxShadow: "4px 4px 4px black" }}
          data-aos="flip-right"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >

          <div className='d-flex flex-column'>
            <h5 style={{ width: "200px" }}>Login Name: </h5>
            <input className='input col-12' type='text' maxLength={10} autoFocus value={loginName} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex flex-column mt-3'>
            <h5 style={{ width: "200px" }}>Full Name: </h5>
            <input className='input col-12' type='text' maxLength={50} value={fullName} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex  flex-column mt-3'>
            <h5 style={{ width: "200px" }}>Phone: </h5>
            <input className='input' type='text' maxLength={50} value={phoneNo} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex flex-column  mt-3'>
            <h5 style={{ width: "200px" }}>Email: </h5>
            <input className='input' type='email' maxLength={50} value={email} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex  flex-column mt-3'>
            <h5 style={{ width: "200px" }}>Address: </h5>
            <input className='input' type='text' maxLength={300} value={address} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex  flex-column mt-3'>
            <h5 style={{ width: "200px" }}>Password: </h5>
            <input className='input' type='password' maxLength={20} value={password} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex  flex-column mt-3'>
            <h5 style={{ width: "200px" }}>Confirm Password: </h5>
            <input className='input' type='password' maxLength={20} value={confPassword} onChange={(e) => { console.log(e.target.value) }} />
          </div>

          <div className='d-flex justify-content-center mt-3'>
            <Button variant='contained' onClick={() => { alert("Form Under Construction...") }}>SUBMIT</Button>
          </div>

        </div>

      </div>
      <Footer />
    </div>
  )
}

export default ContactUS