import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import AOS from 'aos';
import "aos/dist/aos.css";

import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts'

const Customers = () => {
  const navigate = useNavigate()

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    })
  })

  const data = [{
    Product: "Brighto",
    Value: 200,
    Qty: 87,
    Stock: 300
  },
  {
    Product: "Eco Coat",
    Value: 100,
    Qty: 77,
    Stock: 250
  }, {
    Product: "Material",
    Value: 300,
    Qty: 140,
    Stock: 1200
  }
  ];


  return (
    <main>
      <div className='coverCustomer m-0 p-0' data-aos="flip-down">

        {/** 
        <div className='d-flex justify-content-end' style={{ background: "rgb(81, 46, 95)" }}>
          <CancelPresentationIcon className='cancelButton' onClick={() => { navigate('/') }} />
        </div>
*/}
        {/*
        <div className='col-12 d-flex p-2 justify-content-center'>
          <div className='col-lg-5 p-2 col-md-8 col-sm-12 col-12 bg-white text-center'>
            <h3 className='text-dark'>Business Analytics</h3>
            <ResponsiveContainer aspect={3}>
              <LineChart data={data}>

                <CartesianGrid strokeDasharray={"3 2"} fill="lightyellow" />

                <XAxis dataKey={"product"} interval={'preserveStartEnd'} />
                <YAxis />

                <Tooltip contentStyle={{ color: "white" }}
                  labelStyle={{ backgroundColor: "black" }}
                  itemStyle={{ backgroundColor: "lightyellow" }}
                />

                <Legend />
                <Line type={"monotone"} dataKey={"Product"} stroke='darkgreen' activeDot={{ r: 6 }} />
                <Line type={"monotone"} dataKey={"Stock"} stroke='black' activeDot={{ r: 6 }} />
                <Line type={"monotone"} dataKey={"Value"} stroke='blue' activeDot={{ r: 6 }} />
                <Line type={"monotone"} dataKey={"Qty"} stroke='red' activeDot={{ r: 6 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
  */}


      </div>

    </main>
  )
}

export default Customers