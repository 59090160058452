const ProductData = [
  {
    id: 1,
    image: 'ProductImages/Interior/All-Wood-Coat.png',
    Category: "Interior"
  },
  {
    ID: 2,
    image: 'ProductImages/Interior/All-Wood-Lacqure.png',
    Category: "Interior"
  },
  {
    id: 3,
    image: 'ProductImages/Interior/Aroma-Matt-Finish.png',
    Category: "Interior"
  },
  {
    ID: 4,
    image: 'ProductImages/Interior/Brighto-Interior-Primer-1.png',
    Category: "Interior"
  },
  {
    id: 5,
    image: 'ProductImages/Interior/Brighto-Super-Emulsion.png',
    Category: "Interior"
  },
  {
    ID: 6,
    image: 'ProductImages/Interior/Brighto-Super-Sealer.png',
    Category: "Interior"
  },
  {
    id: 7,
    image: 'ProductImages/Interior/Brighto-Wall-Emulsion.png',
    Category: "Interior"
  },
  {
    ID: 8,
    image: 'ProductImages/Interior/Brighto-Wall-Putty.png',
    Category: "Interior"
  },
  {
    ID: 9,
    image: 'ProductImages/Interior/Brighto-Wall-Texture.png',
    Category: "Interior"
  },
  {
    ID: 10,
    image: 'ProductImages/Interior/Interior-Filler.png',
    Category: "Interior"
  },
  {
    ID: 11,
    image: 'ProductImages/Interior/Plastic-Emulsion-2.png',
    Category: "Interior"
  },
  {
    ID: 12,
    image: 'ProductImages/Interior/Stain-Free-2.png',
    Category: "Interior"
  },
  {
    ID: 13,
    image: 'ProductImages/Interior/super-filler-3.png',
    Category: "Interior"
  },
  {
    ID: 14,
    image: 'ProductImages/Interior/Super-Primer-2.png',
    Category: "Interior"
  },
  {
    ID: 15,
    image: 'ProductImages/Interior/super-texture.png',
    Category: "Interior"
  },
  {
    ID: 16,
    image: 'ProductImages/Interior/Synthetic-Enamel.png',
    Category: "Interior"
  },
  {
    ID: 17,
    image: 'ProductImages/Exterior/All-Weather-D.png',
    Category: "Exterior"
  },
  {
    ID: 18,
    image: 'ProductImages/Exterior/Brighto-Red-Oxide-Primer.png',
    Category: "Exterior"
  },
  {
    ID: 19,
    image: 'ProductImages/Exterior/Brighto-Wall-Shield.png',
    Category: "Exterior"
  },
  {
    ID: 20,
    image: 'ProductImages/Exterior/Brighto-Synthetic-Metallic-Enamel-4.png',
    Category: "Exterior"
  },
  {
    ID: 21,
    image: 'ProductImages/Exterior/Clear-Varnish.png',
    Category: "Exterior"
  },
  {
    ID: 22,
    image: 'ProductImages/Exterior/Exterior-Filler.png',
    Category: "Exterior"
  },
  {
    ID: 23,
    image: 'ProductImages/Exterior/Exterior-Primer.png',
    Category: "Exterior"
  },
  {
    ID: 24,
    image: 'ProductImages/Exterior/Synthetic-Enamel.png',
    Category: "Exterior"
  },
  {
    ID: 25,
    image: 'ProductImages/Exterior/All-Wood-Coat.png',
    Category: "Exterior"
  },
  {
    ID: 26,
    image: 'ProductImages/Luxury/perlata-gallon.png',
    Category: "Luxury"
  },
  {
    ID: 27,
    image: 'ProductImages/Luxury/perlex-gallon.png',
    Category: "Luxury"
  },
  {
    ID: 28,
    image: 'ProductImages/Luxury/plaster-gallon.png',
    Category: "Luxury"
  },
  {
    ID: 29,
    image: 'ProductImages/Luxury/velvet-gallon.png',
    Category: "Luxury"
  },
  {
    ID: 30,
    image: 'ProductImages/Luxury/Sterling_Gold-2.png',
    Category: "Luxury"
  },
  {
    ID: 31,
    image: 'ProductImages/Luxury/Sterling_Silver-2.png',
    Category: "Luxury"
  }


]

export default ProductData